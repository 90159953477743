export default [
  '2Do',
  'AirDrop',
  'AirplaneMode',
  'AppStore',
  'ApplePay',
  'Apps',
  'Associate',
  'Battery',
  'Bear',
  'BlindSquare',
  'Blink',
  'Bluetooth',
  'Books',
  'Box',
  'Brightness',
  'Bursts',
  'Calculator',
  'Camera',
  'Captio',
  'CellularData',
  'Chrome',
  'Clear',
  'Clipboard',
  'Clock',
  'CloudApp',
  'Contacts',
  'ContactsCategory',
  'Date',
  'DayOne',
  'Dictation',
  'Dictionary',
  'DoNotDisturb',
  'Documents',
  'DownloadArticle',
  'Downloads',
  'Drafts',
  'Due',
  'Editorial',
  'Evernote',
  'FaceTime',
  'Facebook',
  'Fantastical',
  'FastForward',
  'Flashlight',
  'GIF',
  'Giphy',
  'GoodReader',
  'Graph',
  'HandoffAction',
  'Health',
  'HealthCategory',
  'Home',
  'Image',
  'Imgur',
  'Instagram',
  'Instapaper',
  'Lightroom',
  'LivePhotos',
  'Location',
  'Mail',
  'Maps',
  'Markup',
  'Messages',
  'Microphone',
  'Music',
  'MusicCategory',
  'Network',
  'Notes',
  'Notification',
  'OmniFocus',
  'Opener',
  'Overcast',
  'PDF',
  'Pause',
  'Phone',
  'PhoneNumber',
  'Photos',
  'Pinboard',
  'Placeholder',
  'Pocket',
  'Print',
  'Pythonista',
  'QRCode',
  'QuickLook',
  'QuickTime',
  'RSS',
  'Reminders',
  'Rewind',
  'RichText',
  'Safari',
  'Scripting',
  'ScriptingCategory',
  'Sharing',
  'Shortcuts',
  'Skype',
  'Smiley',
  'Sound',
  'SpeechRecognition',
  'Tally',
  'Text',
  'TheHitList',
  'Things',
  'Todoist',
  'Translate',
  'Trello',
  'Tumblr',
  'Tweetbot',
  'Twitter',
  'URL',
  'Ulysses',
  'Variable',
  'Videos',
  'Weather',
  'Web',
  'WhatsApp',
  'Wi-Fi',
  'WordPress',
  'Wunderlist',
  'iTranslate',
  'iTunes',
];
